const services = [
  { id: 1, name: "painting" },
  { id: 2, name: "upholstery" },
  { id: 3, name: "interior decoration" },
  { id: 4, name: "cleaning services" },
  { id: 5, name: "evacuation services" },
  { id: 6, name: "tiling service" },
  { id: 7, name: "estate management" },
  { id: 8, name: "real estate" },
  { id: 9, name: "construction" },
  { id: 10, name: "plumbing service" },
  { id: 11, name: "event planning" },
];

export default services;
