import OrderForm from "../components/form/OrderForm";

const MakeOrder = () => {
  return (
    <div>
      <OrderForm />
    </div>
  );
};

export default MakeOrder;
