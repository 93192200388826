import { Container } from "../components/form/LoginForm";

const NotFound = () => {
  return (
    <Container>
      <h1>Page not Found</h1>
    </Container>
  );
};

export default NotFound;
