const states = [
  { id: 1, state: "Lagos" },
  { id: 2, state: "Abuja" },
  { id: 3, state: "Rivers" },
  { id: 4, state: "Oyo" },
  { id: 5, state: "Kaduna" },
  { id: 6, state: "Ogun" },
  { id: 7, state: "Osun" },
  { id: 8, state: "Ekiti" },
  { id: 9, state: "Ondo" },
  { id: 10, state: "Edo" },
  { id: 11, state: "Kwara" },
  { id: 12, state: "Abia" },
  { id: 13, state: "Imo" },
  { id: 14, state: "Enugu" },
  { id: 15, state: "Anambra" },
  { id: 16, state: "Ebonyi" },
  { id: 17, state: "Imo" },
  { id: 18, state: "Delta" },
  { id: 19, state: "Bayelsa" },
  { id: 20, state: "Rivers" },
  { id: 21, state: "Cross-river" },
  { id: 22, state: "Akwa-ibom" },
  { id: 23, state: "Kogi" },
  { id: 24, state: "Benue" },
  { id: 25, state: "Kano" },
  { id: 26, state: "Katsina" },
  { id: 27, state: "Kebbi" },
  { id: 28, state: "Bauchi" },
  { id: 29, state: "Sokoto" },
  { id: 30, state: "Nassarawa" },
  { id: 31, state: "Zamfara" },
  { id: 32, state: "Gombe" },
  { id: 33, state: "Yobe" },
  { id: 34, state: "Taraba" },
  { id: 35, state: "Plateau" },
  { id: 36, state: "Borno" },
];

export default states;
